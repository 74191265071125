






























































































































































































































import { ElForm } from "element-ui/types/form";
import { Component, Vue } from "vue-property-decorator";
import {
  questionnaireList,resultPageListByQuestionnaire,resultDetailById
} from "@/serve/evaluatingBefore/evaluatingQuestion";

interface DynamicTags {
  quesOptionContent: string;
  sort: string | number;
  quesOptionRelaVOList: QuesOptionRelaVOList[];
  id?: number | string;
}

interface QuesOptionRelaVOList {
  diseaseScore: number | string; //测评指数
  diseaseId: string | number; //疾病系统id
  id?: number;
}
interface AddData<T, U> {
  quesDesc: T;
  quesKey: T;
  quesName: T;
  quesType: T;
  id?: U;
  quesSubjectList: {
    quesSubjectContent: T;
    quesSubjectIsRequired: T;
    quesSubjectType: T;
    sort: T | U;
    id?: U;
    quesId?: U;
    quesOptionList: {
      quesOptionContent: T; //问卷选项内容
      sort: T | U; //排序
      quesOptionRelaVOList: {
        diseaseScore: T; //测评指数
        diseaseId: T | U; //疾病系统id
      }[];
    }[];
  }[];
}

interface TopicData<T, U> {
  check?: T | boolean;
  quesSubjectContent: T;
  quesSubjectIsRequired: T;
  quesSubjectType: T;
  sort: T | U;
  id?: U;
  quesId?: U;
  arr?: (T | U)[];
  item?: T | undefined;
  itemSort?: T | U | undefined;
  diseaseType?: T | undefined;
  scoreModel?: T | U | undefined;
  projectList?: QuesOptionRelaVOList[];

  quesOptionList: DynamicTags[];
  [propName: string]: any;
}
@Component({
  name: 'EvaluatingQuestionCreates',
})
export default class EvaluatingQuestionCreates extends Vue {
   created() {
    this.questionnaireListApi();
    this.resultPageListByQuestionnaireApi();
  }
 
  public currentPage4 = 1;
  public formLabelWidth = "120px";
  public tableData = [];
  public diseaseType = "";
  public checkQuestion = true;
  public scoreModel = "";
  public riskModel = "";
  public resData: TopicData<string, number>[] = [];
 
  
  public isShowTopicAddItem = false;
  
  public form = {
    
    name: "",
    healthDate: "",
    switchs: false,
    type: "",
    scene:"",
    sex:"",
    title:""
  };
  // TODO:风险评估
  
  // 筛选按钮
  public onSubmit() {
    console.log("筛选",this.form);
    const {healthDate,name,scene,sex,switchs,title,type} = this.form
    this.questionnaireId = String(title)
    this.questionnaireCategory = type
    this.beginTime = healthDate&&healthDate.length?healthDate[0]:""
    this.endTime = healthDate&&healthDate.length?healthDate[1]:""
    this.checkerName = name
    this.checkerMarried = scene
    this.checkerSex = sex
    this.status = switchs?"9":""
    this.resultPageListByQuestionnaireApi()

  }
  public isShow = true
  public returnBtn(){
    this.isShow = true
    
    
  }
  // 重置功能
  public resetForm() {
    this.form = {
    
    name: "",
    healthDate: "",
    switchs: false,
    type: "",
    scene:"",
    sex:"",
    title:""
  };
  this.size=10//分页参数
  this.current = 1//分页参数
  this.questionnaireId = ""//问卷id
  this.questionnaireCategory = ""//问卷类别 0：基础 1：一般 2：深度
  this.beginTime = ''//起始时间
  this.endTime = ''//结束时间
  this.checkerName = ""//搜索参数
  this.checkerMarried = ""//婚姻状况 0:未婚 1：已婚
  this.checkerSex = ""//性别 男 女
  this.status = ""//是否是阳性 是:9 否:0
    console.log("重置",this.form);
    this.resultPageListByQuestionnaireApi()
    
  }
  public handleSizeChange(val: any): void {
    this.size = val;
    this.resultPageListByQuestionnaireApi()
  }
  public handleCurrentChange(val: any): void {
    this.current = val;
    this.resultPageListByQuestionnaireApi()
  }
  public questionnaireList = []
  public async questionnaireListApi() {
    const params: any = {
      type:"",
      scene:"",
      status:"",
      category:""
    }
    const res = await questionnaireList(params);
        if (res.code === 200) {
          console.log("res",res);
          this.questionnaireList = res.data
        }
  }
  public editRow(item: any){
    this.isShow = false
    console.log("item",item);
    this.resultDetailByIdApi(item.id)
    
  }
  /**
   * 统计总数
   */
  public total = 0
  public positiveTotal = 0
  /**
   * resultPageListByQuestionnaireApi相关参数
   */
  public size=10//分页参数
  public current = 1//分页参数
  public questionnaireId = ""//问卷id
  public questionnaireCategory = ""//问卷类别 0：基础 1：一般 2：深度
  public beginTime = ''//起始时间
  public endTime = ''//结束时间
  public checkerName = ""//搜索参数
  public checkerMarried = ""//婚姻状况 0:未婚 1：已婚
  public checkerSex = ""//性别 男 女
  public status = ""//是否是阳性 是:9 否:0
  // resultPageListByQuestionnaire
  public async resultPageListByQuestionnaireApi() {
    const {size,current,questionnaireId,questionnaireCategory,beginTime,endTime,checkerName,checkerMarried,checkerSex,status} = this
    const params: any = {
      size,
      current,
      questionnaireId,
      questionnaireCategory,
      beginTime,
      endTime,
      checkerName,
      checkerMarried,
      checkerSex,
      status
      
    }
    const res = await resultPageListByQuestionnaire(params);
        if (res.code === 200) {
          console.log("res",res);
          this.tableData = res.data.pageData
          this.total = res.data.total
          this.positiveTotal = res.data.positiveTotal

         
        }
  }
  public resultDetailList = []
  public async resultDetailByIdApi(id: any){
    const params: any = {
      id
    }
    const res = await resultDetailById(params);
        if (res.code === 200) {
          console.log("res",res);
         this.resultDetailList = res.data.questionnaireItemList

         
        }
  }
 
}
